<template>
	<div class="login-form">
		<div v-if="passwordForm">
			<div class="login-form__header">
				<v-btn class="login-form__header-button" icon color="#575E60" @click="$router.push({ name: 'Home' })">
					<v-icon class="login-form__header-button-icon">far fa-arrow-left</v-icon>
				</v-btn>
				<h2 class="login-form__header-title">{{ $t('login.updatePassword') }}</h2>
				<div></div>
			</div>
			<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="resetPassword">
				<div class="login-form__inputs">
					<v-text-field
						class="login-form__input"
						:label="$t('login.new_password')"
						v-model="password"
						:rules="passwordRules"
						:type="show ? 'text' : 'password'"
						:color="primaryColor"
						:append-icon="show ? 'visibility' : 'visibility_off'"
						@click:append="show = !show"
						required
					></v-text-field>
					<v-text-field
						class="login-form__input"
						:label="$t('login.repeat-password')"
						:rules="[password === repassword || 'Las contraseñas deben coincidir', repasswordRules]"
						v-model="repassword"
						:type="show ? 'text' : 'password'"
						:color="primaryColor"
						:append-icon="show ? 'visibility' : 'visibility_off'"
						@click:append="show = !show"
						required
					></v-text-field>
				</div>
				<div class="login-form__bottom">
					<v-btn class="login-form__button" color="#2498ff" type="submit" depressed>
						{{ $t('login.recover') }}
					</v-btn>
				</div>
			</v-form>
		</div>
		<div v-else>
			<div class="validate-user__header">
				<v-btn class="validate-user__header-button" icon color="#575E60" @click="frompage ? $router.back() : $router.push({ name: 'Home' })">
					<v-icon class="validate-user__header-button-icon">far fa-arrow-left</v-icon>
				</v-btn>
				<div v-if="!validresetToken">
					<h2 v-if="!validresetToken" class="validate-user__header-title">{{ $t('error.error_token_expiredTitol') }}</h2>
					<p v-if="!validresetToken" class="validate-user__header-subtittle">
						{{ $t('error.error_token_expiredSubtittle') }}
					</p>
				</div>
				<div v-else-if="verified">
					<h2 class="validate-user__header-title">{{ $t('success.succes_passwordconfirmedTitol') }}</h2>
					<p class="validate-user__header-subtittle">
						{{ $t('success.succes_passwordconfirmed') }}
					</p>
				</div>
				<div v-else-if="!verified">
					<h2 class="validate-user__header-title">{{ $t('error.error_passwordconfirmedTitol') }}</h2>
					<p class="validate-user__header-subtittle">
						{{ $t('error.error_passwordconfirmed') }}
					</p>
				</div>
			</div>
			<div class="validate-user__body">
				<div v-if="verified" class="validate-user__body-icon" style="color: green"><i class="fad fa-check-circle"></i></div>
				<div v-else class="validate-user__body-icon" style="color: red"><i class="fad fa-times-circle"></i></div>
			</div>
			<div v-if="verified" class="login-form__bottom">
				<v-btn class="login-form__button" color="#2498ff" :to="{ name: 'Login' }" depressed>
					{{ $t('menu.login') }}
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { resetPassword } from '@/api/sessions.js';
import { tokenValidation } from '@/api/sessions.js';

export default {
	name: 'ResetPassword',
	props: {
		primaryColor: {
			type: String,
			default: '#d4af37'
		},
		frompage: Boolean,
		frompageName: String
	},
	components: {},

	data() {
		return {
			show: false,

			eventname: null,
			loginInfo: null,
			visitorProfile: 'VISITOR',
			showModal: false,
			showPolicyModal: false,
			expiredSessionDialogName: 'expiredSession',
			token: '',
			valid: true,
			password: null,
			passwordRules: [(v) => !!v || 'La contraseña es requerida'],
			repassword: null,
			repasswordRules: (v) => !!v || 'Introduzca la nueva contraseña',
			passwordForm: true,
			verified: null,
			validresetToken: true
		};
	},
	computed: {
		getInfoKey() {
			var session = this.$store.getters.getSession;
			return session.keepSessionAliveInfoKey;
		},

		getPrimaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	mounted() {
		this.getEventName();
		this.checkIfUserHasToken();
	},
	created() {
		this.$puiEvents.$off(`pui-modalDialog-${this.expiredSessionDialogName}-ok`);
	},

	methods: {
		getEventName: function () {
			const aux = window.location.pathname.split('/');
			this.eventname = aux[2];
		},

		async resetPassword() {
			if (this.$refs.form.validate()) {
				var body = {
					password: this.password,
					resetToken: this.token
				};

				let data = await resetPassword(body);
				if (data.responseType === 'success') {
					this.passwordForm = false;
					this.verified = true;
				} else {
					this.passwordForm = false;
					this.verified = false;
				}
				console.log(data);
			}
		},

		getEventLangs() {
			if (this.$store.state.eventInfo.evencataloglanguages === '*') {
				return ['en', 'es', 'ca'];
			} else {
				const langs = this.$store.state.eventInfo.evencataloglanguages.split(',');
				return langs;
			}
		},
		async checkIfUserHasToken() {
			if (!this.$route.query.resetToken) {
				this.$router.push({ name: 'Home' });
			} else {
				this.token = this.$route.query.resetToken;
				let data = await tokenValidation(this.token);
				console.log(data);
				if (!data.data) {
					this.passwordForm = false;
					this.validresetToken = false;
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.login-form {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 2rem;
	width: 552px;
}

.login-form__header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding-bottom: 20px;

	&-button {
		position: absolute;
		left: 0;
		width: 1rem;
		height: 1rem;

		&-icon {
			font-size: 1rem !important;
		}
	}

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1.625rem;
		color: #293133;
	}
}

.login-form__input {
	width: 30.5rem;
}

.login-form__forgotten {
	display: flex;
	justify-content: flex-end;
}

.login-form__bottom {
	display: flex;
	flex-direction: column;
}

.login-form__button {
	margin-top: 20px;
	color: #fff;
}

.login-form__link {
	color: #304ea2;
	text-align: center;
	font-size: 13px;
}

.role-selecton__button {
	color: #fff;
	padding-right: 15px !important;
	padding-left: 15px !important;
	margin: 5px;
}

.validate-user {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 2rem;
}

.validate-user__header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	flex-direction: column;

	&-button {
		position: absolute;
		left: 0;
		width: 1rem;
		height: 1rem;

		&-icon {
			font-size: 1rem !important;
		}
	}

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1.625rem;
		color: #293133;
		padding-bottom: 10px;
		text-align: center;
	}

	&-subtittle {
		font-family: Cabin;
		text-align: center;
		font-size: 1rem;

		color: #293133;
	}
}

.validate-user__body {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&-icon {
		font-size: 10rem;
	}
}
</style>
