<template>
	<div class="login">
		<div class="login__navbar">
			<Navbar />
		</div>
		<div class="login__header" :style="{ 'background-color': primaryColor }">
			<h1 class="login__header-title">{{ $t('login.updatePassword') }}</h1>
		</div>
		<div class="login__body" :style="{ 'background-image': 'url(' + image + ')' }">
			<div class="login__body-sections">
				<ResetPassword :primaryColor="primaryColor" :frompage="frompage" :frompageName="frompageName" />
			</div>
		</div>
		<footer class="login__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import ResetPassword from '@/components/Login/ResetPassword.vue';
import { getDocument } from '@/api/common.js';

export default {
	name: 'ResetPasswordValidation',
	title() {
		return `${this.$t('login.updatePassword')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		ResetPassword,
		Footer
	},
	data() {
		return {
			image: '',
			frompage: false,
			frompageName: null
		};
	},
	mounted() {
		this.getImage();
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {
		async getImage() {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_catalog_button_exhibitor');
			this.image = data[0].url;
		},
		goToHome() {
			this.$store.dispatch('puiRouterGoToUrl', { router: this.$router, url: `/${this.$store.state.eventInfo.evgrurlname}/home` });
		}
	},

	beforeRouteEnter(to, from, next) {
		next((vm) => {
			// access to component instance via `vm`

			if (from.name !== null) {
				vm.frompage = true;
				vm.frompageName = from.name;
			}
		});
	}
};
</script>

<style lang="scss" scoped>
.login {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.login__header {
	background-color: #565567;
	height: 15vh;
	display: flex;
	align-items: center;
}

.login__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
	padding-left: 10%;
	padding-top: 1.5rem;
}

.login__body {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.login__body-sections {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
</style>
